export type ApprovalRequestResponse = {
  Items: ApprovalRequest[];
  ContinuationToken: string;
};
export type ApprovalRequest = {
  id: string;
  CreatedDate: Date;
  ModifiedDate?: Date;
  Status: ApprovalStatus;
  Title: string;
  Requestor: UserInformation;
  Approvers: UserInformation[];
  Updates?: ApprovalAction[];
  Links?: ApprovalLink[];
  Parameters: Object;
  Reminder?: ApprovalReminder;
  Metadata: Object;
  CorrelationKey?: string;
  SourceWorkflow: string;
  Adapter: AdapterInfo;
  SchemaVersion: string;
};

export interface ApprovalAction {
  Status: ApprovalStatus;
  Update: Record<string, any>;
}

export interface ApprovalLink{
  Text: string;
  Url: string;
}

export interface ApprovalReminder {
  Hours: number;
  Sent: boolean;
}

export type UserInformation = {
  Id: string;
  Name: string;
  Email: string;
  Department: string;
  JobTitle: string;
};

export enum ApprovalStatus {
  Denied,
  Approved,
  Pending,
  Cancelled,
  New,
  All,
}

export namespace ApprovalStatus {
  export function toString(status: ApprovalStatus): string {
    if (typeof status === "string") {
      return status;
    }
    return ApprovalStatus[status];
  }
  export function toInt(status: ApprovalStatus): number {
    return status;
  }
}

export type AdapterInfo = {
  Name: string;
  SchemaVersion: string;
};
