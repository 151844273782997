import {
  Avatar,
  Badge,
  Body1Stronger,
  Button,
  Caption1,
  Caption2,
  Card,
  CardFooter,
  CardHeader,
  DialogOpenChangeData,
  DialogOpenChangeEvent,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  Link,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  OverlayDrawer,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableRow,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import React from "react";
import { ApprovalService } from "../../../Services/approvals/approvals";
import {
  ApprovalRequest,
  ApprovalStatus,
} from "../../../Services/types/approvalRequest";
import { ApprovalUpdateRequest } from "../../../Services/types/approvalUpdateRequest";
import { formatDate, getStatusBadgeColor } from "../../../utils/utils";
import { TeamsFxContext } from "../../Context";
import { ApprovalHistory } from "../ApprovalHistory/ApprovalHistory";
import "./ApprovalDrawer.css";

export const ApprovalDrawerComponent = ({
  drawerOpen,
  approvalRequest,
  onDrawerClosed,
  userEmail,
  closeOnUpdate = false,
}: {
  drawerOpen: boolean;
  approvalRequest: ApprovalRequest | string | null;
  onDrawerClosed: (result: ApprovalRequest | null) => void;
  userEmail: string;
  closeOnUpdate: boolean;
}) => {
  const { teamsUserCredential } = React.useContext(TeamsFxContext);
  const [updateErrorFlag, setUpdateErrorFlag] = React.useState<boolean>(false);
  const [loadErrorFlag, setLoadErrorFlag] = React.useState<boolean>(false);
  const [isWorking, setIsWorking] = React.useState<boolean>(false);

  const [request, setRequest] = React.useState<ApprovalRequest | null>(null);

  React.useEffect(() => {
    if (drawerOpen === true) {
      setLoadErrorFlag(false);
      setIsWorking(true);
      setRequest(null);
      if (typeof approvalRequest === "string") {
        (async () => {
          try {
            if (!teamsUserCredential) {
              throw new Error("TeamsFx SDK is not initialized.");
            }

            let r = await ApprovalService.getApprovalItem(
              approvalRequest,
              teamsUserCredential
            );
            setRequest(r);
          } catch (ex) {
            console.error(ex);
            setLoadErrorFlag(true);
          }
          setIsWorking(false);
        })();
      } else {
        setRequest(approvalRequest);
        setIsWorking(false);
      }
    }
  }, [drawerOpen]);

  const IsApprover = () => {
    if (request?.Approvers.find((x) => x.Email === userEmail) !== undefined) {
      return true;
    }
    return false;
  };
  const canEdit = request?.Status === ApprovalStatus.Pending && IsApprover();

  const closeDrawer = (result: ApprovalRequest | null) => {
    if (isWorking) return;
    setUpdateErrorFlag(false);
    onDrawerClosed(result);
  };

  const onOpenChange = (
    _event: DialogOpenChangeEvent,
    data: DialogOpenChangeData
  ) => {
    if (data.open === false) {
      if (request != null) {
        closeDrawer({ ...request });
      } else {
        closeDrawer(null);
      }
    }
  };

  const updateRequest = async (decision: ApprovalStatus) => {
    if (!teamsUserCredential) {
      throw new Error("TeamsFx SDK is not initialized.");
    }
    let updateRequest: ApprovalUpdateRequest = {
      requestId: request?.id!,
      approval: decision,
    };
    setIsWorking(true);
    try {
      let response = await ApprovalService.updateApproval(
        updateRequest,
        teamsUserCredential
      );
      setRequest(response);
      if (closeOnUpdate) {
        closeDrawer(response);
      }
    } catch (ex) {
      setUpdateErrorFlag(true);
      console.error(ex);
    }
    setIsWorking(false);
  };

  const requestDetailsRenderer: {
    name: string;
    value: (request: ApprovalRequest) => JSX.Element;
  }[] = [
    {
      name: "Source",
      value: (r) => <>{r.Adapter.Name}</>,
    },
    {
      name: "Workflow",
      value: (r) => <>{r.SourceWorkflow}</>,
    },
    {
      name: "Requestor",
      value: (r) => (
        <TableCellLayout
          media={
            <Avatar
              aria-label={r.Requestor.Email}
              name={r.Requestor.Name}
              badge={{}}
            ></Avatar>
          }
        >
          {r.Requestor.Name}
        </TableCellLayout>
      ),
    },
    {
      name: "Approvers",
      value: (r) => (
        <Table size="extra-small">
          <TableBody>
            {r.Approvers.map((approver) => (
              <TableRow key={approver.Email}>
                <TableCell style={{ paddingLeft: "0" }}>
                  <TableCellLayout
                    media={
                      <Avatar
                        aria-label={approver.Email}
                        name={approver.Name}
                        badge={{}}
                      ></Avatar>
                    }
                  >
                    {approver.Name}
                  </TableCellLayout>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ),
    },
  ];

  const triggerApprove = () => updateRequest(ApprovalStatus.Approved);
  const triggerDeny = () => updateRequest(ApprovalStatus.Denied);

  return (
    <div>
      {
        <OverlayDrawer
          size="medium"
          position="end"
          modalType="modal"
          open={drawerOpen}
          onOpenChange={onOpenChange}
        >
          <DrawerHeader>
            <DrawerHeaderTitle
              action={
                <Button
                  appearance="subtle"
                  aria-label="Close"
                  icon={<Dismiss24Regular />}
                  disabled={isWorking}
                  onClick={() =>
                    closeDrawer(request != null ? { ...request } : null)
                  }
                />
              }
            >
              Request details
            </DrawerHeaderTitle>
          </DrawerHeader>
          {loadErrorFlag && (
            <DrawerBody>
              <MessageBar intent="error">
                <MessageBarBody>
                  <MessageBarTitle>Error</MessageBarTitle>
                  Could not retrieve the request from the server.
                </MessageBarBody>
              </MessageBar>
            </DrawerBody>
          )}
          {request == null && !loadErrorFlag && (
            <DrawerBody
              style={
                request != null && !loadErrorFlag
                  ? undefined
                  : { display: "flex", justifyContent: "center" }
              }
            >
              <Spinner size="extra-large" label="Loading..." />
            </DrawerBody>
          )}
          {request != null && (
            <DrawerBody>
              {updateErrorFlag && (
                <MessageBar intent="error">
                  <MessageBarBody>
                    <MessageBarTitle>Error</MessageBarTitle>
                    Could not update the request status.
                  </MessageBarBody>
                </MessageBar>
              )}

              <Card>
                <CardHeader
                  header={<Body1Stronger>{request.Title}</Body1Stronger>}
                  description={
                    <Caption1>
                      Submitted on {formatDate(request.CreatedDate)}
                    </Caption1>
                  }
                />

                <Table size="extra-small" className="requestDetailsTable">
                  <TableBody>
                    {requestDetailsRenderer.map((r) => (
                      <TableRow key={r.name}>
                        <TableCell>{r.name}</TableCell>
                        <TableCell>{r.value(request)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                <CardFooter>
                  <div className="approvalDrawerControls">
                    {canEdit && (
                      <div className="approvalDrawerButtons">
                        {!isWorking && (
                          <>
                            <Button
                              appearance="primary"
                              onClick={triggerApprove}
                            >
                              Approve
                            </Button>
                            <Button
                              appearance="secondary"
                              onClick={triggerDeny}
                            >
                              Deny
                            </Button>
                          </>
                        )}
                        {isWorking && (
                          <Spinner
                            size="extra-tiny"
                            label="Saving changes"
                            style={{ padding: "6px 0" }}
                          />
                        )}
                      </div>
                    )}
                    {!canEdit && (
                      <>
                        <Badge
                          size="large"
                          appearance="filled"
                          color={getStatusBadgeColor(request.Status)}
                        >
                          {ApprovalStatus.toString(request.Status)}
                        </Badge>
                      </>
                    )}
                    {request.Links?.length && (
                      request.Links!.map(link=><Link target="_blank" href={link.Url}>
                        {link.Text}
                      </Link>)
                    )}
                  </div>
                </CardFooter>
              </Card>

              <h3>Request parameters</h3>
              <Table size="extra-small">
                <TableBody>
                  {Object.entries(request.Parameters).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell>{key}</TableCell>
                      <TableCell>{value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <h3>History</h3>
              <ApprovalHistory
                correlationKey={request.CorrelationKey as string}
              />
            </DrawerBody>
          )}
        </OverlayDrawer>
      }
    </div>
  );
};
