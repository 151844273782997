import { ApprovalStatus } from "../Services/types/approvalRequest";

const formatDate = (date: Date) => {
    var d = new Date(date);
    let day = (d.getMonth() + 1).toString().padStart(2, '0');
    let month = d.getDate().toString().padStart(2, '0');
    let year = d.getFullYear();
    let hourModulo = (d.getHours() % 12 === 0) ? 12 : d.getHours() % 12;
    let hour = hourModulo.toString().padStart(2, '0');
    let period = d.getHours() >= 12 ? "PM" : "AM";
    let minutes = d.getMinutes().toString().padStart(2, '0');
    return `${day}/${month}/${year} ${hour}:${minutes} ${period}`;
}

const getStatusBadgeColor = (status: ApprovalStatus) => {
    switch (status) {
        case ApprovalStatus.Pending:
            return "warning";
        case ApprovalStatus.Approved:
            return "success";
        case ApprovalStatus.Denied:
            return "danger";
        case ApprovalStatus.Cancelled:
            return "brand";
    }
    return "subtle";
}

export {formatDate, getStatusBadgeColor}