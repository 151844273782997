import { useContext } from "react";
import { TeamsFxContext } from "./Context";
import config from "./sample/lib/config";
import { MainCustomerRefund } from "./Workflows/Customer_Refund/Main_Customer_Refund";

const showFunction = Boolean(config.apiName);

//Get Info From MS Graph Function App



export default function Workflows() {
  const { themeString } = useContext(TeamsFxContext);
  return (
    <div
      className={themeString === "default" ? "light" : themeString === "dark" ? "dark" : "contrast"}    >
      <h1>Under Construction</h1>
    </div>
  );
}
