import * as axios from "axios";
import {
  BearerTokenAuthProvider,
  createApiClient,
  TeamsUserCredential,
} from "@microsoft/teamsfx";
import config from "../components/sample/lib/config";
import { User } from "./types/user";

const functionName = config.apiName || "myFunc";

async function callUserInfoFunction(
  teamsUserCredential: TeamsUserCredential
): Promise<User> {
  try {
    const apiBaseUrl = config.apiEndpoint + "/api/";
    const apiClient = createApiClient(
      apiBaseUrl,
      new BearerTokenAuthProvider(
        async () => (await teamsUserCredential.getToken(""))!.token
      )
    );
    const response = await apiClient.get(functionName);
    return response.data;
  } catch (err: unknown) {
    if (axios.default.isAxiosError(err)) {
      let funcErrorMsg = "";

      if (err?.message === "Network Error") {
        funcErrorMsg = "Unable to retrieve user information.";
      } else {
        funcErrorMsg = err.message;
        if (err.response?.data?.error) {
          funcErrorMsg += ": " + err.response.data.error;
        }
      }

      throw new Error(funcErrorMsg);
    }
    throw err;
  }
}

export default callUserInfoFunction;
