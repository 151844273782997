import {
  BearerTokenAuthProvider,
  TeamsUserCredential,
  createApiClient,
} from "@microsoft/teamsfx";
import config from "../../components/sample/lib/config";
import {
  ApprovalRequest,
  ApprovalRequestResponse,
} from "../types/approvalRequest";
import { ApprovalUpdateRequest } from "../types/approvalUpdateRequest";
import { SearchRequest } from "../types/searchRequest";
import { ApprovalHistoryState } from "../types/approvalHistoryElement";

const apiBaseUrl = config.apiEndpoint + "/api/";

async function getToken(credential: TeamsUserCredential) {
  return (await credential.getToken(""))!.token;
}

function getApiClient(credential: TeamsUserCredential) {
  const apiClient = createApiClient(
    apiBaseUrl,
    new BearerTokenAuthProvider(() => getToken(credential))
  );
  return apiClient;
}

export namespace ApprovalService {
  export async function getApprovals(
    request: SearchRequest,
    teamsUserCredential: TeamsUserCredential
  ) {
    const apiClient = getApiClient(teamsUserCredential);

    const response = await apiClient.post("getApprovals", request);
    return response.data as ApprovalRequestResponse;
  }

  export async function getApprovalItem(
    itemId: string,
    teamsUserCredential: TeamsUserCredential
  ) {
    const apiClient = getApiClient(teamsUserCredential);

    const result = await apiClient.post("getApproval", { requestId: itemId });
    return result.data as ApprovalRequest;
  }

  export async function updateApproval(
    updateRequest: ApprovalUpdateRequest,
    teamsUserCredential: TeamsUserCredential
  ) {
    const apiClient = getApiClient(teamsUserCredential);

    const response = await apiClient.post("updateApproval", updateRequest);
    return response.data as ApprovalRequest;
  }

  export async function getApprovalHistory(
    correlationKey: string,
    teamsUserCredential: TeamsUserCredential
  ) {
    const apiClient = getApiClient(teamsUserCredential);

    const result = await apiClient.post("getApprovalHistory", {
      correlationKey: correlationKey
    });
    return result.data as ApprovalHistoryState[];
  }
}
