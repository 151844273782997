import { useContext, useEffect, useState } from "react";
import { TeamsFxContext } from "../../src/components/Context";
import { User } from "../Services/types/user";
import CallUserInfoFunction from "../Services/userInfo";

const useUser = () => {
  const [needConsent, setNeedConsent] = useState(false);
  const [userInfo, setUserInfo] = useState<User>();
  const [errorResponse, setErrorResponse] = useState<any>();
  const { teamsUserCredential } = useContext(TeamsFxContext);

  const fetchUserInfo = async () => {
    try {
      if (!teamsUserCredential) {
        throw new Error("TeamsFx SDK is not initialized.");
      }
      if (needConsent) {
        await teamsUserCredential!.login(["User.Read"]);
        setNeedConsent(false);
      }
      try {
        const functionRes = await CallUserInfoFunction(teamsUserCredential);

        setUserInfo(functionRes);
        return functionRes;
      } catch (error: any) {
        if (error.message.includes("The application may not be authorized.")) {
          setNeedConsent(true);
        } else {
          throw error;
        }
      }
    } catch (error: any) {
      setErrorResponse(error);
    }
  };

  useEffect(() => {
    fetchUserInfo();
    // eslint-disable-next-line
  }, []);

  return { userInfo: userInfo, error: errorResponse };
};

export default useUser;
