import {
  HashRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import Tab from "./Tab";
import Workflows from "./Workflows";
import useUser from "../Hooks/useUser";
import { UserContext } from "../Context/UserContext";

export default function Routers() {
  const user = useUser();

  return (
    <UserContext.Provider value={user}>
      <Router>
        <Routes>
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="/workflows" element={<Workflows />} />
          <Route path="/tab" element={<Tab />} />
          <Route path="*" element={<Navigate to={"/tab"} />}></Route>
        </Routes>
      </Router>
    </UserContext.Provider>
  );
}
