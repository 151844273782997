import { useContext, useEffect, useState } from "react";
import "./Welcome.css";

import {
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  SelectTabData,
  SelectTabEvent,
  Spinner,
  Tab,
  TabList,
  TabValue,
} from "@fluentui/react-components";
import { app } from "@microsoft/teams-js";
import { UserContext } from "../../Context/UserContext";
import { ApprovalsComponent } from "../ApprovalTab/Approvals/Approvals";

export function Welcome(props: {
  showFunction?: boolean;
  environment?: string;
}) {
  const user = useContext(UserContext);
  const [selectedTab, setSelectedTab] = useState<TabValue>("approvals");
  const [selectedItemId, setSelectedItemId] = useState<string>("");
  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedTab(data.value);
  };

  useEffect(() => {
    (async () => {
      let context = await app.getContext();
      let urlPayload = context.page.subPageId;
      if (urlPayload !== undefined && urlPayload !== "") {
        // todo: open approval
        try {
          let payload = JSON.parse(JSON.stringify(urlPayload));
          setSelectedTab(payload.tab);
          setSelectedItemId(payload.id);
        } catch (ex) {
          console.error(ex);
        }
      }
    })();
  }, []);

  const clearSelectedItemId = () => setSelectedItemId("");

  return (
    <div className="welcome page flexfill nooverflow">
      <div className="narrow page-padding flexfill nooverflow">
        {user.userInfo === undefined && user.error === undefined && (
          <Spinner style={{ margin: 100 }} />
        )}
        {user.error !== undefined && (
          <MessageBar intent="error">
            <MessageBarBody>
              <MessageBarTitle>Error</MessageBarTitle>
              {(user.error as Error).message}
            </MessageBarBody>
          </MessageBar>
        )}
        {user.userInfo !== undefined && (
          <>
            <h1 className="Left welcomeHeading">
              Welcome, {user.userInfo.DisplayName}!
            </h1>
            <div className="flexfill nooverflow">
              <TabList selectedValue={selectedTab} onTabSelect={onTabSelect}>
                <Tab id="Approvals" value="approvals">
                  Approvals
                </Tab>
                <Tab id="Requests" value="requests">
                  Requests
                </Tab>
              </TabList>
              <div
                style={{ marginTop: "15px" }}
                className="flexfill nooverflow"
              >
                <div
                  style={{
                    display: selectedTab === "approvals" ? "flex" : "none",
                  }}
                  className="flexfill nooverflow"
                >
                  <ApprovalsComponent
                    email={user.userInfo!.Mail}
                    listType={"Approvers"}
                    selectedId={
                      selectedTab === "approvals" ? selectedItemId : ""
                    }
                    onDrawerClosed={clearSelectedItemId}
                  />
                </div>
                <div
                  style={{
                    display: selectedTab === "requests" ? "flex" : "none",
                  }}
                  className="flexfill nooverflow"
                >
                  <ApprovalsComponent
                    email={user.userInfo!.Mail}
                    listType={"Requestor"}
                    selectedId={
                      selectedTab === "requests" ? selectedItemId : ""
                    }
                    onDrawerClosed={clearSelectedItemId}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
