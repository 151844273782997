import {
  Avatar,
  Badge,
  Body1Stronger,
  Caption1,
  Card,
  Skeleton,
  SkeletonItem,
} from "@fluentui/react-components";
import * as React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  ApprovalRequest,
  ApprovalStatus,
} from "../../../Services/types/approvalRequest";
import { formatDate, getStatusBadgeColor } from "../../../utils/utils";
import "./ApprovalList.css";

export const ApprovalListComponent = ({
  listType,
  approvals,
  onItemClicked,
  onNext,
  hasMoreItems,
  isLoading,
  showEndMessage,
}: {
  listType: "Approvers" | "Requestor";
  approvals: ApprovalRequest[];
  onItemClicked: (item: ApprovalRequest) => void;
  onNext: () => void;
  hasMoreItems: boolean;
  isLoading: boolean;
  showEndMessage: boolean;
}) => {
  const userType = listType === "Approvers" ? "Requestor" : "Approver";
  const scrollableRef = React.useRef(null);

  const getUser = (item: ApprovalRequest) => {
    return listType === "Approvers" ? item.Requestor : item.Approvers[0];
  };

  const columnDefs: {
    name: string;
    class: string;
    render: (item: ApprovalRequest) => JSX.Element;
  }[] = [
    {
      name: "Title",
      class: "approvalCardInfoTitle",
      render: (item) => (
        <>
          <Body1Stronger
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
            }}
            title={item.Title}
          >
            {item.Title}
          </Body1Stronger>
          <div>
            <Caption1>{formatDate(item.CreatedDate)}</Caption1>
          </div>
        </>
      ),
    },
    {
      name: "Source",
      class: "approvalCardInfoSource",
      render: (item) => (
        <>
          <div className="approvalCardInfoLabel">Source</div>
          <div>{item.Adapter.Name}</div>
        </>
      ),
    },
    {
      name: "Workflow",
      class: "approvalCardInfoWorkflow",
      render: (item) => (
        <>
          <div className="approvalCardInfoLabel">Workflow</div>
          <div>{item.SourceWorkflow}</div>
        </>
      ),
    },
    {
      name: userType,
      class: "approvalCardInfoUser",
      render: (item) => (
        <>
          <div className="approvalCardInfoLabel">{userType}</div>
          <div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
            <Avatar
              aria-label={getUser(item).Email}
              name={getUser(item).Name}
              badge={{}}
              size={20}
            />
            {getUser(item).Name}
          </div>
        </>
      ),
    },
    {
      name: "Status",
      class: "approvalCardInfoStatus",
      render: (item) => (
        <>
          <div className="approvalCardInfoLabel">Status</div>
          <Badge
            color={getStatusBadgeColor(item.Status)}
          >
            {ApprovalStatus.toString(item.Status)}
          </Badge>
        </>
      ),
    },
  ];

  const tableHeaders = columnDefs.map((col) => (
    <div className={`approvalCardInfoItem ${col.class}`} key={col.name}>
      {col.name}
    </div>
  ));

  const tableItems = approvals.map((item) => (
    <Card
      key={item.id}
      onClick={() => onItemClicked(item)}
      className="approvalCard"
    >
      <div className="approvalCardInfo">
        {columnDefs.map((col) => (
          <div className={`approvalCardInfoItem ${col.class}`} key={col.name}>
            {col.render(item)}
          </div>
        ))}
      </div>
    </Card>
  ));

  const triggerNext = () => {
    onNext();
  };

  return (
    <div className="approvalCardContainer flexfill nooverflow">
      <div className="approvalCardInfo approvalCardInfoHeader">
        {tableHeaders}
      </div>
      <div className="scrollableResults" ref={scrollableRef}>
        <InfiniteScroll
          next={triggerNext}
          hasMore={hasMoreItems}
          dataLength={approvals.length}
          scrollableTarget={scrollableRef.current}
          loader={<></>}
          key={scrollableRef.current}
        >
          {tableItems}
          {isLoading && (
            <Skeleton>
              <SkeletonItem size={48} />
            </Skeleton>
          )}
          {showEndMessage && (
            <div style={{ padding: "5px 0px", textAlign: "center" }}>
              No more items.
            </div>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};
